import { ApartmentRounded, PlaceRounded, SegmentRounded } from '@mui/icons-material';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, Toolbar } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const drawerWidth = 240;

const AppSidebar = () => {
  const location = useLocation();
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton dense component={Link} to="/" selected={location.pathname === '/'}>
              <ListItemIcon>
                <ApartmentRounded />
              </ListItemIcon>
              <ListItemText primary="Companies" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton dense component={Link} to="/standard-fields" selected={location.pathname === '/standard-fields'}>
              <ListItemIcon>
                <SegmentRounded />
              </ListItemIcon>
              <ListItemText primary="Standard Fields" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton dense component={Link} to="/msa-premium-defaults" selected={location.pathname === '/msa-premium-defaults'}>
              <ListItemIcon>
                <PlaceRounded />
              </ListItemIcon>
              <ListItemText primary="MSA Premiums" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default AppSidebar;
