import { Logout } from '@mui/icons-material';
import { Toolbar, Backdrop, CircularProgress, AppBar, Box, Typography, Tooltip, IconButton, Avatar, MenuItem, ListItemIcon, Menu } from '@mui/material';
import { useState } from 'react';
import { useLogoutUser, useUser } from '@helpers/auth0';

const AppHeader = () => {
  const user = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const logoutUser = useLogoutUser();

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setIsLoggingOut(true);
    await logoutUser();
  };

  let firstInitial = '';
  let lastInitial = '';
  if (user.name) {
    firstInitial = user.name.charAt(0);
  }
  if (user.family_name) {
    lastInitial = user.family_name.charAt(0);
  }

  const isMenuOpen = Boolean(anchorEl);

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} color="default">
        <Toolbar>
          <Box mr={2} component="img" width={40} src="/assets/logo.png" alt="logo" />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Zebel Admin
          </Typography>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleOpenMenu}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={isMenuOpen ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isMenuOpen ? 'true' : undefined}
            >
              <Avatar sx={{ width: 36, height: 36 }}>
                {firstInitial}
                {lastInitial}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={isMenuOpen}
            onClose={handleCloseMenu}
            onClick={handleCloseMenu}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoggingOut}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AppHeader;
