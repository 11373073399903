import { Alert, Typography } from '@mui/material';
import List from './components/List';
const MsaPremiumDefaultsPage = () => {
  return (
    <>
      <Typography variant="h5" component="h1" sx={{ mb: 2 }}>
        MSA Premium Defaults
      </Typography>
      <Alert sx={{ mb: 2 }} severity="info">
        MSA premium defaults are the default values for the msa premium company tables. When a company is created, these values will be copied into their company settings as a
        starting point.
      </Alert>
      <List />
    </>
  );
};

export default MsaPremiumDefaultsPage;
