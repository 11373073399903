import { Button, Box, Typography, CircularProgress, TextField, Card, CardContent } from '@mui/material';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { usePostLogin } from '@data/login';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const postLogin = usePostLogin();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      setError('email required');
      return;
    }
    if (!password) {
      setError('password required');
      return;
    }
    setSubmitting(true);
    setError('');

    try {
      await postLogin({ username: email, password });
      navigate('/');
    } catch (err) {
      setSubmitting(false);
      setError(err.message);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <Card>
      <CardContent>
        <Box textAlign="center" padding=" 0px 8px" mb={2}>
          <Typography variant="h1" fontSize="24px" margin="24px 0 16px">
            Welcome
          </Typography>
          <Typography variant="body1" fontSize="14px">
            Log in to Zebel to continue to Zebel App.
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column">
            <Box mb={2}>
              <TextField autoFocus fullWidth label="Email" type="email" autoComplete="current-email" value={email} onChange={handleEmailChange} />
            </Box>
            <Box mb={2}>
              <TextField label="Password" fullWidth type="password" autoComplete="current-password" value={password} onChange={handlePasswordChange} />
            </Box>
            {error && (
              <Typography color="red" fontSize="12px">
                {error}
              </Typography>
            )}
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} disabled={submitting} startIcon={submitting ? <CircularProgress size={12} /> : null}>
              Continue
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default LoginForm;
