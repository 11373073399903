import { Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import State from './State';
import { useMsaPremiumDefaults } from '@data/msaPremiumDefaults';
import Spinner from '@components/Spinner';

const getMsasByStates = (data) => {
  const states = {};
  data.query.forEach((msaPremiumDefaultUid) => {
    const msaPremiumDefault = data.content[msaPremiumDefaultUid];
    if (!states[msaPremiumDefault.msa.state]) {
      states[msaPremiumDefault.msa.state] = [];
    }
    states[msaPremiumDefault.msa.state].push(msaPremiumDefault);
  });
  return Object.keys(states)
    .sort((a, b) => a.localeCompare(b))
    .map((stateKey) => ({ stateKey, msas: states[stateKey].sort((a, b) => a.msa.msa_name.localeCompare(b.msa.msa_name)) }));
};

const List = () => {
  const msaPremiumDefaults = useMsaPremiumDefaults();

  if (msaPremiumDefaults.isLoading) {
    return <Spinner />;
  }

  if (msaPremiumDefaults.error) {
    return <Alert severity="error">An error occurred loading data.</Alert>;
  }

  const states = getMsasByStates(msaPremiumDefaults.data);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>MSA</TableCell>
            <TableCell># of MSAs</TableCell>
            <TableCell>Premium Range</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {states.map((state) => (
            <State key={state.state} state={state} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
