import { Box } from '@mui/material';
import LoginForm from './components/LoginForm';

const LoginPage = () => {
  return (
    <Box height="100vh" width="100vw" display="flex" alignItems="center" justifyContent="center">
      <LoginForm />
    </Box>
  );
};

export default LoginPage;
