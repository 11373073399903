import axios from 'axios';
import Cookies from 'js-cookie';

import { getCookieDomain } from '@helpers/api';

const usePostLogin = () => {
  const postLogin = async (payload) => {
    try {
      const url = `/login`;
      const response = await axios.post(url, payload);
      Cookies.set(`token`, response.data.token, { domain: getCookieDomain() });
    } catch (err) {
      throw Error(err.response.data.message);
    }
  };

  return postLogin;
};

export { usePostLogin };
