import React from 'react';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './app';
import CompaniesPage from '@pages/companies/Page';
import StandardFieldsPage from '@pages/standardFields/Page';
import MsaPremiumDefaultsPage from '@pages/msaPremiumDefaults/Page';
import LoginPage from '@pages/login/Page';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { getTheme } from '@helpers/mui';
import { getAuth0Props } from '@helpers/auth0';
import { getApiUrl } from '@helpers/api';

axios.defaults.baseURL = getApiUrl();

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <CompaniesPage />
      },
      {
        path: 'standard-fields',
        element: <StandardFieldsPage />
      },
      {
        path: 'msa-premium-defaults',
        element: <MsaPremiumDefaultsPage />
      }
    ]
  }
]);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Auth0Provider {...getAuth0Props()}>
    <ThemeProvider theme={getTheme()}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </Auth0Provider>
);
