import { CircularProgress, InputAdornment, TableCell, TableRow, TextField } from '@mui/material';
import { useState } from 'react';
import { usePutMsaPremiumDefault, useMsaPremiumDefaults } from '@data/msaPremiumDefaults';

const MsaPremiumDefault = ({ msaPremiumDefault }) => {
  const msaPremiumDefaults = useMsaPremiumDefaults();
  const [premium, setPremium] = useState(msaPremiumDefault.value);
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const putMsaPremiumDefault = usePutMsaPremiumDefault();

  const handleChange = (e) => {
    setPremium(e.target.value);
  };

  const handleSave = async (e) => {
    const value = parseFloat(e.target.value);

    if (isNaN(value)) {
      setError('Please enter a valid number');
      return;
    }

    try {
      setError('');
      setIsSaving(true);
      const editedMsaPremiumDefault = await putMsaPremiumDefault(msaPremiumDefault.uid, { value });
      msaPremiumDefaults.mutate(
        (currentMsaPremiumDefaults) => ({
          content: Object.fromEntries(
            Object.entries(currentMsaPremiumDefaults.content).map(([key, value]) => (key === msaPremiumDefault.uid ? [key, editedMsaPremiumDefault] : [key, value]))
          ),
          query: currentMsaPremiumDefaults.query
        }),
        false
      );
      setIsSaving(false);
    } catch (err) {
      setIsSaving(false);
      setError('An error occurred saving the data');
    }
  };

  return (
    <TableRow hover sx={{ '&:last-of-type > *': { borderBottom: 'unset' } }}>
      <TableCell>{msaPremiumDefault.msa.msa_name}</TableCell>
      <TableCell sx={{ width: '130px' }} align="right">
        <TextField
          error={!!error}
          helperText={error}
          value={premium}
          onBlur={handleSave}
          onChange={handleChange}
          type="number"
          InputProps={{ endAdornment: <InputAdornment position="end">{isSaving ? <CircularProgress size={12} /> : '%'}</InputAdornment> }}
        />
      </TableCell>
    </TableRow>
  );
};

export default MsaPremiumDefault;
