import { createTheme } from '@mui/material';

const getTheme = () => {
  return createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#f7c36b'
      },
      secondary: {
        main: '#f50057'
      }
    },
    components: {
      MuiTextField: {
        defaultProps: {
          size: 'small',
          variant: 'standard'
        }
      },
      MuiList: {
        defaultProps: {
          dense: true
        }
      },
      MuiTable: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiMenuItem: {
        defaultProps: {
          dense: true
        }
      }
    }
  });
};

export { getTheme };
