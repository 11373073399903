import { Box, Container, LinearProgress, Toolbar } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth, useUser } from './helpers/auth0';
import AppSidebar from '@components/AppSidebar';
import AppHeader from '@components/AppHeader';

const App = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, loginWithRedirect, authStrategy } = useAuth();
  const user = useUser();

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!isAuthenticated) {
    if (authStrategy === 'zebel-api') {
      navigate('/login');
    } else {
      loginWithRedirect();
    }
    return null;
  }

  if (!user.roles.includes('zebeler')) {
    return 'You are not authorized to access this application.';
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ display: 'flex' }}>
        <AppHeader />
        <AppSidebar />
        <Toolbar />
        <Box component="main" sx={{ flexGrow: 1, px: 3, pb: 4, pt: 12 }}>
          <Outlet />
        </Box>
      </Box>
    </Container>
  );
};

export default App;
