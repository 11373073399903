import { TableCell, TableRow, Collapse, Table, TableHead, TableBody, Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { ExpandMoreRounded } from '@mui/icons-material';
import { stateKeys } from '../../../helpers/states';
import MsaPremiumDefault from './MsaPremiumDefault';

const getPremiumRange = (msas) => {
  const premiums = msas.map((msa) => parseFloat(msa.value));
  const min = Math.min(...premiums);
  const max = Math.max(...premiums);
  if (min === max) {
    return `${min}%`;
  }
  return `${min}% - ${max}%`;
};

const State = ({ state }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (!stateKeys[state.stateKey]) {
    return null;
  }

  return (
    <>
      <TableRow hover sx={{ cursor: 'pointer', '& > *': { borderBottom: 'unset' } }} onClick={handleToggleExpand}>
        <TableCell>{stateKeys[state.stateKey]}</TableCell>
        <TableCell>{state.msas.length}</TableCell>
        <TableCell>{getPremiumRange(state.msas)}</TableCell>
        <TableCell align="right" sx={{ width: '100px' }}>
          <IconButton aria-label="expand row" size="small">
            <ExpandMoreRounded sx={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all .2s linear' }} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottomColor: isExpanded ? '' : 'transparent' }} colSpan={4}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>MSA</TableCell>
                    <TableCell>Premium</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.msas.map((msaPremiumDefault) => (
                    <MsaPremiumDefault key={msaPremiumDefault.uid} msaPremiumDefault={msaPremiumDefault} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default State;
